import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
//import HtmlParser from '../helpers/htmlParser';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
export function LastTwoResources () {
    const data = useStaticQuery(graphql`
    query ResourcesQuery {
        allNodePageResource(sort: {fields: created, order: DESC}, limit: 2, filter: {status: {eq: true}, field_send_to_menu: {eq: true}}) {
    nodes {
      title
      field_intro {
        processed
      }
      created
      relationships {
        field_image {
          id
          notStyledImage {
            childImageSharp {
              id
              gatsbyImageData
            }
          }
        }
      }
      path {
        alias
      }
      status
      field_send_to_menu
    }
  }
    }
  `);

    const blogs = data.allNodePageResource.nodes.map((item, index) => {

        let summary = <HTMLEllipsis
                        unsafeHTML={item?.field_intro?.processed.replace(/(<([^>]+)>)/gi, "")}
                        maxLine='3'
                        ellipsis='...'
                        basedOn='letters'
                        />
        return (
        <div className='menu-resources-item menu-view-item' key={item.title}>
                <div className="menu-resources-icon menu-view-icon">{/*<Img fixed={item.relationships.field_image.notStyledImage.childImageSharp.fixed} />*/}<GatsbyImage alt={item.title} image={item.relationships.field_image.notStyledImage.childImageSharp.gatsbyImageData} maxWidth={75} /></div>
                <div className="menu-view-content">
                    <div className='menu-resources-title menu-view-title'>
                        <Link to={item.path.alias}>{item.title}</Link>
                      {/*   <div className="menu-view-date">{date}</div> */}
                    </div>
                    <div className='menu-resources-summary menu-view-summary'>{summary}</div>
                </div>
            
                </div>
      
            
            
        )
    } )

    return (
        <>
            {blogs}
            
                    
        </>
    )
}

