import { Link, useStaticQuery, graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LastTwoBlog } from "../components/lastTwoBlog.js"
import { LastTwoEventsAndNews } from "../components/lastTwoEventsAndNews"
import { LastTwoResources } from "../components/lastTwoResources.js"

function showFreeConsultation() {

    if (document.getElementById) {
        var fcOpen = document.getElementById("consultation__popup--box");
        fcOpen.classList.add("visible");
    }
    return false;

}

const Navigation = () => {
    const hasSubMenus = (menu_item) => menu_item.links.length !== null;
    const [activeSubmenu, setActiveSubmenu] = useState(null);
    const renderViews = (classes, menu_link) => {
        if (classes) {
            if (classes.includes("last-two-blog")) {
                return (
                    <ul className='dropdown-view nav-last-two-element'><li className="blog-dropdown"><LastTwoBlog></LastTwoBlog><div className="btn-primary view-more-menu"><Link to={menu_link}>View More</Link></div></li></ul>
                )
            }
            if (classes.includes("last-two-event-news")) {
                return (
                    <ul className='dropdown-view nav-last-two-element'><li className="event-news-dropdown"><LastTwoEventsAndNews></LastTwoEventsAndNews><div className="btn-primary view-more-menu"><Link to={menu_link}>View More</Link></div></li></ul>
                )
            }
            if (classes.includes("last-two-resources")) {
                return (
                    <ul className='dropdown-view nav-last-two-element'><li className="resources-dropdown"><LastTwoResources></LastTwoResources><div className="btn-primary view-more-menu"><Link to={menu_link}>View More</Link></div></li></ul>
                )
            }
            if (classes.includes("free-consultation")) {
                return (
                    <ul className='dropdown-view nav-last-two-element contact-consultation'><li className="free-consultation-dropdown"><FontAwesomeIcon icon={"comments"} /><button id="free-consultation-btn" onClick={() => showFreeConsultation()}>Free Consultation</button></li></ul>
                )
            }
        }
    }
       
    const recursiveSubMenuRender = (menu_item) => {
        if (hasSubMenus(menu_item)) {
            return (
                <ul className='menu'>
                    {menu_item.links.map(function (_child_menu, index) {
                        if (!_child_menu.url.path.includes('node')) {
                            return (
                                <li className={_child_menu.class} key={_child_menu.label + index}>
                                    
                                    <Link className="sub-menu-link" to={_child_menu.url.path} activeClassName='active'>
                                        <FontAwesomeIcon icon={_child_menu.icon} />
                                        {_child_menu.label}
                                    </Link>
                                    {recursiveSubMenuRender(_child_menu)}
                                </li>
                            );
                        }
                        return false;
                    })}
                </ul>
            );
        }
    };
    const menu_data = useStaticQuery(graphql`
        query Menu {
            drupal {
                mainMenu: menuByName(name: "main") {
                    links {
                        label
                        icon
                        class: attribute(key: "class")
                        url {
                            path
                        }
                        links {
                            url {
                                path
                            }
                            label
                            icon
                            class: attribute(key: "class")
                            links {
                                url {
                                    path
                                }
                                label
                                icon
                                class: attribute(key: "class")
                                links {
                                    label
                                    icon
                                    class: attribute(key: "class")
                                    url {
                                        path
                                    }
                                    links {
                                        url {
                                            path
                                        }
                                        label
                                        icon
                                        class: attribute(key: "class")
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    
    const mainMenu = menu_data.drupal.mainMenu;
    const { links } = mainMenu;
        function clicked(e) {
            if (!e.target.classList.contains('active')) {
                document.querySelector('html').classList.remove('disable-scroll');
            }
        }
    useEffect(() => {
        let links = document.querySelectorAll('.sub-menu-link');
        links.forEach((item) => {
            if (item.classList.contains('active')) {
                const lvl = item.parentElement;
                const lvltwo = lvl.parentElement;
                lvltwo.parentElement.classList.add('active');

                const lvltri = lvltwo.parentElement;
                lvltri.parentElement.classList.add('active')

                const lvlquad = lvltri.parentElement;
                lvlquad.parentElement.classList.add('active')
            }
        })
    })
    return (
        <nav id='main_nav'>
            <ul className='mobile-menu__first--ul'>
                
                {links.map((_menu_item, index) => {
                    const curIndex = Number(index);

                    const classes = classNames('main-menu-item', {
                        submenu: hasSubMenus(_menu_item),
                        subopened: activeSubmenu === curIndex
                    });
                    if (!_menu_item.url.path.includes('node')) {
                        return (
                            <li className={classes} key={_menu_item.label + index}>
                                <Link                         
                                    className={_menu_item.class + " menu-link"}
                                    to={_menu_item.url.path}
                                    activeClassName='active'
                                    onMouseDown={clicked}>
                                    <FontAwesomeIcon icon={_menu_item.icon} />
                                    {_menu_item.label}
                                </Link>
                                {/* <Link
                                            className='menu-link'
                                            to={_menu_item.url.path}
                                            activeClassName='active'>
                                            {_menu_item.label}
                                        </Link> */}
                               
                                {renderViews(_menu_item.class,_menu_item.url.path) || (
                                    <div
                                        className='arrows-box'
                                        onClick={() => {
                                            if (activeSubmenu !== curIndex) {
                                                setActiveSubmenu(curIndex);
                                            } else {
                                                setActiveSubmenu(null);
                                            }
                                        }}
                                    ></div>
                                )}
                                
                                {hasSubMenus(_menu_item) && (
                                    <div
                                        aria-hidden="true"
                                        className='arrows-box'
                                        onClick={() => {
                                            if (activeSubmenu !== curIndex) {
                                                setActiveSubmenu(curIndex);
                                            } else {
                                                setActiveSubmenu(null);
                                            }
                                        }}></div>
                                    )
                                }
                                {recursiveSubMenuRender(_menu_item)}
                            </li>
                        );
                    } else {
                        return <></>;
                    }
                })}
            </ul>
        </nav>
    );
};

export default Navigation;
