import React, { useLayoutEffect, useState } from 'react';

function scrollto() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

export default function ScrollTop() {
  const [scrollPosition, setPosition] = useState(0);
  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);
  
  if (scrollPosition > 900) {
      return (
        <div className='scroll-top--box' id='scroll-top--box' onKeyDown={() => scrollto()} onClick={() => scrollto()}>
            <div id="scroll-top--btn" aria-hidden="true"></div>
        </div>
      )
  } else {
      return false
  }
}