import React from "react"
import { Link,useStaticQuery, graphql } from "gatsby"
//import HtmlParser from '../helpers/htmlParser';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LastTwoBlog () {
    const data = useStaticQuery(graphql`
    query BlogQuery {
        allNodePageBlog(limit: 2, sort: {fields: created, order: DESC}) {
            nodes {
                id
                created
                title
                body {
                    processed
                }
                path {
                    alias
                }
            }
        }
    }
  `);

    const blogs = data.allNodePageBlog.nodes.map((item, index) => {

        var rawdate = item.created;
        var na = rawdate.toString();
        var date1 = na.slice(0, 10);
        var date2 = date1.split(' ')[0].split('-');
        var dateFull = date2[1] + '/' + date2[2] + '/' + date2[0];

        const processedText = item?.body?.processed.replace(/(<([^>]+)>)/gi, "")

        let summary = <HTMLEllipsis
                        unsafeHTML={processedText.slice(0,89)}
                        maxLine='3'
                        ellipsis='...'
                        basedOn='letters'
                        />
                        
        return (
        <div className='menu-blog-item menu-view-item' key={item.title}>
                <div className="menu-blog-icon menu-view-icon"><FontAwesomeIcon icon='comments' /></div>
                <div className="menu-view-content">
                    <div className='menu-blog-title menu-view-title'>
                        <Link to={item.path.alias}>{item.title}</Link>
                        <div className="menu-view-date">{dateFull}</div>
                    </div>
                    <div className='menu-blog-summary menu-view-summary'>{summary} ...</div>
                </div>
            
                </div>
      
            
            
        )
    } )

    return (
        <>
            {blogs}        
        </>
    )
}

