import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from 'react';
import classNames from 'classnames';
import Navigation from "./navigation"
import amLogo from "../images/activemedia.png"

const Header = ({ siteTitle }) => {
  const [menuOpened, setMenuOpened] = useState(false);

  const headerClasses = classNames('header', {
    opened: menuOpened,
  });

  return (
    <header id="header-section" className={headerClasses}>
            <div className="header__center">
                <figure>
                    <Link to="/"><img src={amLogo} alt="ActiveMedia logo"></img></Link>
                </figure>
                <div aria-hidden="true" className='hamburger-menu-icon' onClick={() => setMenuOpened(!menuOpened)}>
                    <div className='line'></div>
                </div>
                <Navigation />
            </div>
  </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
