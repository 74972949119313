/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import FreeConsultation from "./freeConsultation"
import ScrollTop from "./scrollTop"
import "../styles/core.scss";


const Layout = ({ children }) => {

  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>

      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main id='main'>
        <FreeConsultation />
        <ScrollTop />
        {children}
      </main>

      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
