import React from 'react';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function hideFreeConsultation() {

    if (document.getElementById) {
        var fcClose = document.getElementById("consultation__popup--box");
        fcClose.classList.remove("visible");
    }
    return false;

}

const FreeConsultation = () => {
    return (
        <div id="consultation__popup--box" className="consultation__popup--box">
            <div className="consultatioin__popup--style">
                <div className="consultation__popup--container">
                    <div className="consultation__popup">
                        <div className="consultation__popup--title">
                            <h2>Free Consultation</h2>
                        </div>
                        <div className="consultation__popup--text">
                            Let's chat about how we can help your business. No risk, no obligation.
                        </div>
                    </div>
                </div>
                <div className="consultation__info--container-bg">
                    <div className="consultation__info--container">
                        <div className="consultation__info--phone">
                            <div className="consultation__info--phone--fafaicon"><FontAwesomeIcon icon={['fas', 'phone-alt']} /></div>
                            <p><strong>Phone:</strong> +1 650-353-2040</p>
                        </div>
                        <div className="consultation__info--toll">
                            <div className="consultation__info--toll--fafaicon"><FontAwesomeIcon icon={['fas', 'phone-square-alt']} /></div>
                            <p><strong>Toll-Free:</strong> +1 855-997-9195</p>
                        </div>
                        <div className="consultation__info--skype">
                            <div className="consultation__info--skype--fafaicon"><FontAwesomeIcon icon={['fab', 'skype']} /></div>
                            <p><strong>Skype:</strong> ActiveMediaUSA</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="popup__close--btn" aria-hidden="true" onKeyDown={() => hideFreeConsultation()} onClick={() => hideFreeConsultation()}>X</div>
        </div>
     );
};

export default FreeConsultation