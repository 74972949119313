import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendar, faComment, faComments, faLock, faSortDown, faHeart, faEnvelope, faBullhorn, faChevronUp, faCogs, faChartLine, faSearch, faHandPointUp, faShapes, faMobile, faEdit, faLaptop, faTablet, faImage, faChild, faTasks, faUsers, faPencilAlt, faThumbsUp, faStar, faBriefcase, faWrench, faShare, faSuitcase, faCalendarAlt, faPhoneAlt, faPhone, faCoffee, faPhoneSquareAlt, faPhoneSquare, faCode, faTabletAlt, faNewspaper, faMobileAlt, faPhotoVideo, faShareAlt, faQuestionCircle, faEye, faLink, faRetweet, faQuestion, faThList, faCheckSquare, faFileAlt, faPuzzlePiece, faChartBar, faExclamationTriangle, faBars, faGamepad, faQrcode, faLightbulb, faPaste, faBook, faParagraph, faCopy, faRocket, faUser, faFastForward, faTimes, faFilter, faTv, faExclamation, faBuilding, faFax, faGlobeAmericas, faMapMarkedAlt, faExclamationCircle, faCubes, faMapMarkerAlt, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { faDrupal, faFacebookF, faLinkedinIn, faSkype, faTwitter } from '@fortawesome/free-brands-svg-icons'

library.add(
    faSkype,
    faMapMarkedAlt,
    faTwitter,
    faLinkedinIn,
    faFacebookF,
    faDrupal,
    far,
    faEye,
    faLink,
    faRetweet,
    faQuestion,
    faThList,
    faMapMarkerAlt,
    faCheckSquare,
    faFileAlt,
    faPuzzlePiece,
    faChartBar,
    faExclamationCircle,
    faExclamationTriangle,
    faBars,
    faGamepad,
    faQrcode,
    faLightbulb,
    faPaste,
    faBook,
    faParagraph,
    faCopy,
    faRocket,
    faUser,
    faFastForward,
    faTimes,
    faFilter,
    faTv,
    faExclamation,
    faBuilding,
    faFax,
    faGlobeAmericas,
    faCalendar,
    faComment,
    faComments,
    faLock,
    faCode,
    faTabletAlt,
    faChevronDown,
    faNewspaper,
    faSortDown,
    faHeart,
    faEnvelope,
    faBullhorn,
    faChevronUp,
    faCogs,
    faChartLine,
    faSearch,
    faHandPointUp,
    faShapes,
    faMobile,
    faMobileAlt,
    faPhotoVideo,
    faShareAlt,
    faQuestionCircle,
    faEdit,
    faLaptop,
    faTablet,
    faImage,
    faChild,
    faTasks,
    faUsers,
    faPencilAlt,
    faThumbsUp,
    faStar,
    faBriefcase,
    faWrench,
    faShare,
    faSuitcase,
    faCalendarAlt,
    faPhoneAlt,
    faPhone,
    faCoffee,
    faPhoneSquareAlt,
    faPhoneSquare,
    faCubes
)