import React from "react"
import { Link,useStaticQuery, graphql } from "gatsby"
//import HtmlParser from '../helpers/htmlParser';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LastTwoEventsAndNews () {
   const data = useStaticQuery(graphql`
    query EventNewsQuery {
        drupal {
    nodeQuery(filter: {conditions: {field: "type", value: "page_news", operator: EQUAL}, groups: {conditions: {field: "type", value: "page_event", operator: EQUAL}, groups: {conditions: {field: "status", value: "1", operator: EQUAL}}, conjunction: AND}, conjunction: OR}, limit: 2, sort: {field: "created", direction: DESC}) {
      count
      entities {
        entityUrl {
          path
          routed
        }
        entityCreated
        entityBundle
        ... on Drupal_NodePageEvent {
          nid
          uuid
          created
          title
          body {
            processed
            summaryProcessed
          }
        }
        ... on Drupal_NodePageNews {
          nid
          uuid
          created
          title
          body {
            processed
            summaryProcessed
          }
        }
      }
    }
  }
    }
  `);

    const blogs = data.drupal.nodeQuery.entities.map((item, index) => {

        var rawdate = item.entityCreated;
        var na = rawdate.toString();
        var date1 = na.slice(0, 10);
        var date2 = date1.split(' ')[0].split('-');
        var dateFull = date2[1] + '/' + date2[2] + '/' + date2[0];

        const processedText = item?.body?.processed.replace(/(<([^>]+)>)/gi, "")

        let summary = <HTMLEllipsis
                        unsafeHTML={processedText.slice(0,89)}
                        maxLine='3'
                        ellipsis='...'
                        basedOn='letters'
                        />
        let icon = 'newspaper';
        let ionclass = 'menu-view-news';
        if (item.entityBundle === 'page_event') {
            icon = 'calendar-alt';
            ionclass = 'menu-view-event';
        }
        return (
        <div className='menu-blog-item menu-view-item' key={item.title}>
                <div className={"menu-blog-icon menu-view-icon "+ionclass}><FontAwesomeIcon icon={icon} /></div>
                <div className="menu-view-content">
                    <div className='menu-blog-title menu-view-title'>
                        <Link to={item.entityUrl.path}>{item.title}</Link>
                        <div className="menu-view-date">{dateFull}</div>
                    </div>
                    <div className='menu-blog-summary menu-view-summary'>{summary} ...</div>
                </div>
            
            </div>
            
        )
    } )

    return (
        <>
            {blogs}
        
                    
        </>
    )
}

