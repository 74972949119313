import React from 'react';
import { Link } from 'gatsby';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import amgpartner from "../images/am-gpartner-2.png";
import ams3partner from "../images/am-s3-partner.png";
import ammap from "../images/am-fo-map.png";
import amwhite from "../images/am-logo-white.png";

const rawdate = new Date();
const currentdate = rawdate.getFullYear()

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="footer__container--topbox">
                    <img src={amwhite} alt=""></img>
                    <h4>Can you afford not to be found on the web?TM</h4>
                </div>
                <div className="footer__container--middlebox">
                    <div className="footer__container--middlebox--logos">
                        <div className="google">
                            <figure><img src={amgpartner} alt="Google partner"></img></figure>
                        </div>
                        <div className="sempo">
                            <Link to="https://getflywheel.com/"><figure><img src={ams3partner} alt="Sempo member"></img></figure></Link>
                        </div>
                    </div>
                    <div className="footer__container--middlebox--map">
                        <Link to="/contact#map-container"><figure><img src={ammap} alt="World map"></img></figure></Link>
                    </div>
                    <div className="footer__container--middlebox--info">
                        <div className="footer__container--middlebox--info--links">
                            <ul>
                                <li className="footer__container--middlebox--info--links--facebook">
                                    <Link to="https://www.facebook.com/activemediausa"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></Link>
                                    <p className="hide-this-element">Facebook</p>
                                </li>
                                <li className="footer__container--middlebox--info--links--twitter">
                                    <Link to="https://twitter.com/ActiveMedia_USA"><FontAwesomeIcon icon={['fab', 'twitter']} /></Link>
                                    <p className="hide-this-element">Twitter</p>
                                </li>
                                <li className="footer__container--middlebox--info--links--linkedin">
                                    <Link to="https://www.linkedin.com/company/activemedia"><FontAwesomeIcon icon={['fab', 'linkedin-in']} /></Link>
                                    <p className="hide-this-element">Linkedin</p>
                                </li>
                            </ul>
                        </div>
                        <div className="footer__info--contact">
                            <ul>
                                <li className="footer__info--phone">
                                    <div className="footer__info--flex">
                                        <FontAwesomeIcon icon={'phone'} />
                                        <p><strong>Phone: </strong>+1 650-353-2040</p>
                                    </div>
                                </li>
                                <li className="footer__info--toll">
                                    <div className="footer__info--flex">
                                        <FontAwesomeIcon icon={'phone-square'} />
                                        <p><strong>Toll-Free: </strong>+1 855-997-9195</p>
                                    </div>
                                </li>
                                <li className="footer__info--skype">
                                    <div className="footer__info--flex">
                                        <FontAwesomeIcon icon={['fab', 'skype']} />
                                        <p><strong>Skype: </strong>ActiveMediaUSA</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__bottombox">
                <div className="footer__bottombox--container">
                    <div className="footer__bottombox--container--rights">
                        <p>© 1996-{currentdate} ActiveMedia. All Rights Reserved.</p>
                    </div>
                    <div className="footer__bottombox--container--btn">
                        <div className="footer-contact"><FontAwesomeIcon icon={'envelope'} /> <Link to="/contact">Contact Us</Link></div>
                        <div className="footer-policy"><FontAwesomeIcon icon={'lock'} /> <Link to="/privacy-policy">Privacy Policy</Link></div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer